<template>
  <page-content>
    <page-title heading="รายงานภาพรวมแผนการสอน">
      <template v-slot:right>
        <b-button variant="danger" @click="exportReport">
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </b-button>

        <!-- <download-excel
          class="btn btn-danger cursor-pointer"
          :fields="export_fields"
          :fetch="exportReport"
        >
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </download-excel> -->
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <filter-fiscal-year-term v-model="formFiscalYearTerm">
        </filter-fiscal-year-term>
      </b-col>

      <b-col cols="12" class="mt-2">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master>

        <!-- <filter-master
          v-model="formfilter"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master> -->
      </b-col>
    </b-row>

    <report-overview-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="TableParams"
      action-label=""
      :show-action="false"
      :see-detail="true"
      :show-delete="false"
      @seeDetail="onSeeDetail"
      @delete="onDelete"
    />
  </page-content>
</template>

<script>
// import vSelect from "vue-select";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import ReportOverviewTable from "../../components/table/ReportOverview";
import FilterMaster from "../../components/form/FilterMaster";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
import { Auth, Report } from "../../models";

// const currentYear = new Date().getFullYear() + 543;
const defaultValue = "ทั้งหมด";

export default {
  components: {
    // vSelect,
    PageContent,
    PageTitle,
    ReportOverviewTable,
    FilterMaster,
    FilterFiscalYearTerm,
    // DownloadExcel,
  },

  data() {
    return {
      keyword: "",
      // terms: ["1", "2"],
      planStatuses: ["ทำแผนการสอน", "ไม่ทำแผนการสอน"],
      isPlan: "",
      // fiscalYearFrom: "",
      // termFrom: "",
      // fiscalYearTo: "",
      // termTo: "",
      formfilter: {},
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },
      deleteData: {},
      transactionId: null,
      isExporting: false,
      showModal: false,
      showModalDelete: false,
      headquarterExportFields: {
        หน่วย: "m_headquarters_name",
        จำนวนครูทั้งหมด: "count_total_teachers",
        จำนวนครูเข้าสอน: "count_teachers_status_teach",
        จำนวนครูไม่เข้าสอน: "count_teachers_status_not_teach",
        บันทึกแผนการสอน: "count_teachers_create_plan",
        ไม่บันทึกแผนการสอน: "count_teachers_not_create_plan",
        // บันทึกแผนการสอน: "count_teachers_complete_plan",
        โรงเรียน: "count_schools",
        ห้องเรียน: "count_classes",
        นักเรียน: "count_students",
      },
      divisionExportFields: {
        จังหวัด: "m_division_name",
        จำนวนครูทั้งหมด: "count_total_teachers",
        จำนวนครูเข้าสอน: "count_teachers_status_teach",
        จำนวนครูไม่เข้าสอน: "count_teachers_status_not_teach",
        บันทึกแผนการสอน: "count_teachers_create_plan",
        ไม่บันทึกแผนการสอน: "count_teachers_not_create_plan",
        // บันทึกแผนการสอน: "count_teachers_complete_plan",
        โรงเรียน: "count_schools",
        ห้องเรียน: "count_classes",
        นักเรียน: "count_students",
      },
      stationExportFields: {
        สถานี: "m_station_name",
        จำนวนครูทั้งหมด: "count_total_teachers",
        จำนวนครูเข้าสอน: "count_teachers_status_teach",
        จำนวนครูไม่เข้าสอน: "count_teachers_status_not_teach",
        บันทึกแผนการสอน: "count_teachers_create_plan",
        ไม่บันทึกแผนการสอน: "count_teachers_not_create_plan",
        // บันทึกแผนการสอน: "count_teachers_complete_plan",
        โรงเรียน: "count_schools",
        ห้องเรียน: "count_classes",
        นักเรียน: "count_students",
      },

      teacherExportFields: {
        // ภาค: "headquarter",
        // จังหวัด: "division",
        // สถานี: "station",
        "ยศ ชื่อ-สกุล": "user_name",
        // ชื่อ: "first_name",
        // สกุล: "last_name",
        ตำแหน่ง: "position_name",
        // เบอร์โทรศัพท์: "phone_number",
        // ปีเกษียณ: "retire_year",
        // ระดับเข็ม: "medal",
        บันทึกแผนการสอน: "create_plan",
        สถานะ: "user_status",
      },
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    // fiscalYears() {
    //   return [
    //     currentYear - 5,
    //     currentYear - 4,
    //     currentYear - 3,
    //     currentYear - 2,
    //     currentYear - 1,
    //     currentYear,
    //     currentYear + 1,
    //     currentYear + 2,
    //     currentYear + 3,
    //     currentYear + 4,
    //     currentYear + 5,
    //   ];
    // },

    fields() {
      const fields = [
        { key: "order", label: "ลำดับ" },
        { key: "m_station_name", label: "สถานี" },
        { key: "count_total_teachers", label: "จำนวนครูทั้งหมด" },
        { key: "count_teachers_create_plan", label: "บันทึกแผนการสอน" },
        { key: "count_teachers_not_create_plan", label: "ไม่บันทึกแผนการสอน" },
        // { key: "count_teachers_complete_plan", label: "บันทึกแผนการสอน" },
        { key: "count_schools", label: "โรงเรียน" },
        { key: "count_classes", label: "ห้องเรียน" },
        { key: "count_students", label: "นักเรียน" },
      ];

      return fields;
    },

    TableParams() {
      const { formfilter } = this;

      return {
        ...formfilter,
        ...this.getFilterTermYear(),
      };
    },
  },

  methods: {
    clearKeyword() {},

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    async fetchExport() {
      let promise;

      let data = [],
        additionalParams = {};

      this.isExporting = true;

      if (this.formfilter && this.formfilter.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.formfilter.mHeadquarterId
        );
      }

      if (this.formfilter && this.formfilter.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.formfilter.mDivisionId);
      }

      if (this.formfilter && this.formfilter.mStationId) {
        this.$set(additionalParams, "mStationId", this.formfilter.mStationId);
      }

      additionalParams = {
        ...additionalParams,
        ...this.getFilterTermYear(),
      };

      try {
        promise = await Report.api().exportTeachingOverview(
          { ...additionalParams },
          { save: false }
        );

        ({ data = [] } = promise.response.data);
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
      } finally {
        this.isExporting = false;
      }

      return data;
    },

    async exportReport() {
      let headquarterData = [],
        divisionData = {},
        stationData = {},
        teacherData = {};

      let data = await this.fetchExport();

      const teacherHeader = Object.keys(this.teacherExportFields);
      const headquarterHeader = Object.keys(this.headquarterExportFields);
      const divisionHeader = Object.keys(this.divisionExportFields);
      const stationHeader = Object.keys(this.stationExportFields);

      if (data && data.length) {
        headquarterData = data.map((record) => {
          const res = {};

          for (const col in this.headquarterExportFields) {
            if (Object.hasOwnProperty.call(this.headquarterExportFields, col)) {
              const field = this.headquarterExportFields[col];

              res[col] = record[field];
            }
          }

          const { m_divisions = [] } = record;

          if (m_divisions && m_divisions.length) {
            this.$set(
              divisionData,
              record.m_headquarters_name,
              m_divisions.reduce((acc_division, m_division) => {
                // set inital teacher data for each division
                this.$set(teacherData, m_division.m_division_name, {});

                const division = {};

                for (const divisionCol in this.divisionExportFields) {
                  if (
                    Object.hasOwnProperty.call(
                      this.divisionExportFields,
                      divisionCol
                    )
                  ) {
                    const divisionField =
                      this.divisionExportFields[divisionCol];

                    division[divisionCol] = m_division[divisionField];
                  }
                }

                const { m_stations = [] } = m_division;

                if (m_stations && m_stations.length) {
                  // collect station teacher data into division
                  this.$set(
                    stationData,
                    m_division.m_division_name,
                    m_stations.reduce((acc_station, m_station) => {
                      this.$set(
                        teacherData[m_division.m_division_name],
                        m_station.m_station_name,
                        [
                          ...m_station.teachers.map((teacher) => {
                            // let medal = "ระดับต้น";

                            // if (teacher.total_time) {
                            //   if (teacher.total_time >= 200) {
                            //     medal = "ระดับเหรียญทอง";
                            //   } else if (teacher.total_time >= 150) {
                            //     medal = "ระดับเหรียญเงิน";
                            //   } else if (teacher.total_time >= 100) {
                            //     medal = "ระดับเหรียญทองแดง";
                            //   }
                            // }

                            let user_name = "";

                            const teacherRank = teacher.m_rank;

                            user_name = `${teacher.first_name} ${teacher.last_name}`;

                            if (teacherRank) {
                              user_name = `${teacherRank.m_rank_name} ${user_name}`;
                            }

                            return {
                              ...teacher,
                              // rank: teacher.m_rank
                              //   ? teacher.m_rank.m_rank_name
                              //   : "-",
                              create_plan: teacher.create_plan
                                ? "บันทึก"
                                : "ไม่บันทึก",
                              user_name,
                              // phone_number: teacher.phone_number
                              //   ? `${teacher.phone_number.substring(
                              //       0,
                              //       3
                              //     )}-${teacher.phone_number.substring(
                              //       3,
                              //       6
                              //     )}-${teacher.phone_number.substring(6, 10)}`
                              //   : "-",
                              // medal,
                              // retire_year: teacher.retire_year || "-",
                            };
                          }),
                        ]
                      );

                      this.$delete(m_station, "teachers");

                      const station = {};

                      for (const stationCol in this.stationExportFields) {
                        if (
                          Object.hasOwnProperty.call(
                            this.stationExportFields,
                            stationCol
                          )
                        ) {
                          const stationField =
                            this.stationExportFields[stationCol];

                          station[stationCol] = m_station[stationField];
                        }
                      }

                      this.$set(acc_station, m_station.mStationId, station);

                      return acc_station;
                    }, {})
                  );
                }

                this.$set(acc_division, m_division.mDivisionId, division);

                return acc_division;
              }, {})
            );
          }

          return res;
        });
      }

      const ws = XLSX.utils.json_to_sheet(headquarterData, {
        header: headquarterHeader,
      });

      const wb = XLSX.utils.book_new();
      const teacherWb = XLSX.utils.book_new();

      if (!this.formfilter.mDivisionId) {
        XLSX.utils.book_append_sheet(wb, ws, "ภาพรวม");
      }

      for (const headquaterName in divisionData) {
        if (divisionData[headquaterName]) {
          let sheetDivisionData = divisionData[headquaterName];

          if (sheetDivisionData) {
            sheetDivisionData = Object.values(sheetDivisionData);

            const dws = XLSX.utils.json_to_sheet(sheetDivisionData, {
              header: divisionHeader,
            });

            if (!this.formfilter.mStationId) {
              XLSX.utils.book_append_sheet(wb, dws, headquaterName);
            }

            sheetDivisionData.forEach((divisionSheet) => {
              const divisionName = divisionSheet["จังหวัด"];

              let sheetStationData = stationData[divisionName];

              if (sheetStationData) {
                sheetStationData = Object.values(sheetStationData);

                const sws = XLSX.utils.json_to_sheet(sheetStationData, {
                  header: stationHeader,
                });

                XLSX.utils.book_append_sheet(wb, sws, divisionName);

                this.$delete(stationData, divisionName);
              }
            });

            this.$delete(divisionData, headquaterName);
          }
        }
      }

      for (const divisionName in teacherData) {
        if (Object.hasOwnProperty.call(teacherData, divisionName)) {
          const divisionTeachers = teacherData[divisionName];

          let divisionTeacherData = [];

          Object.entries(divisionTeachers).forEach((entry) => {
            const stationName = entry[0];

            let stationTeachers = entry[1];

            stationTeachers = stationTeachers.map((teacher, index) => {
              const exportedTeacher = {
                ลำดับ: index + 1,
              };

              for (const teacherCol in this.teacherExportFields) {
                if (
                  Object.hasOwnProperty.call(
                    this.teacherExportFields,
                    teacherCol
                  )
                ) {
                  const teacherField = this.teacherExportFields[teacherCol];

                  exportedTeacher[teacherCol] = teacher[teacherField];
                }
              }

              return exportedTeacher;
            });

            // console.log(teachers, teacherHeader);
            // If filter in station level, add sheet as station level
            if (this.formfilter.mStationId) {
              // station level
              const stws = XLSX.utils.json_to_sheet(stationTeachers, {
                header: ["ลำดับ", ...teacherHeader],
              });

              XLSX.utils.book_append_sheet(teacherWb, stws, stationName);
            } else {
              // If not, collect teachers to division level
              divisionTeacherData = [
                ...divisionTeacherData,
                ...stationTeachers.map((stationTeacher) => {
                  this.$set(stationTeacher, "สถานี", stationName);

                  return stationTeacher;
                }),
              ];
            }
          });

          if (divisionTeacherData.length) {
            // division level
            divisionTeacherData = divisionTeacherData.map((record, index) => {
              this.$set(record, "ลำดับ", index + 1);

              return record;
            });

            const dtws = XLSX.utils.json_to_sheet(divisionTeacherData, {
              header: ["ลำดับ", "สถานี", ...teacherHeader],
            });

            XLSX.utils.book_append_sheet(teacherWb, dtws, divisionName);
          }

          this.$delete(teacherData, divisionName);
        }
      }

      /* bookType can be any supported output type */
      const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

      const wbout = XLSX.write(wb, wopts);

      /* the saveAs call downloads a file on the local machine */
      saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        "รายงานภาพรวมแผนการสอน.xlsx"
      );

      const teacherWbout = XLSX.write(teacherWb, wopts);
      saveAs(
        new Blob([teacherWbout], { type: "application/octet-stream" }),
        "รายงานภาพรวมครู D.A.R.E..xlsx"
      );
    },

    async onSeeDetail(item) {
      this.transactionId = item.id;
      this.showModal = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
