<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :action-label="actionLabel"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :per-page="10"
      >
      </base-table>
    </div>

    <div v-if="!isFetching" class="card-body p-0">
      <base-table
        ref="teacherTable"
        :provider="teacherTableDataProvider"
        :fields="teacherTableFields"
        :current-page.sync="teacherTableCurrentPage"
        :number-of-pages="teacherTableNumberOfPages"
        :per-page="teacherTablePerPage"
        :show-action="false"
        :show-refresh="false"
      >
        <template #cell(create_plan)="{ item }">
          <span
            :class="{
              'text-success': item.create_plan === 'บันทึก',
              'text-danger': item.create_plan === 'ไม่บันทึก',
            }"
          >
            {{ item.create_plan }}
          </span>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
// import { calculateRetireYear } from "../../helpers/datepicker-helper";
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, Report } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "เพิ่ม/แก้ไข",
    },
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    seeDetail: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      teacherTableCurrentPage: 1,
      teacherTableNumberOfPages: 1,
      teacherTablePerPage: 10,
      teacherTableData: [],
      teacherTableFields: [
        // { key: "order", label: "ลำดับ" },
        { key: "headquarter", label: "ภาค" },
        { key: "division", label: "จังหวัด" },
        { key: "station", label: "สถานี" },
        { key: "user_name", label: "ชื่อ-สกุล" },
        // { key: "rank", label: "ยศ" },
        // { key: "first_name", label: "ชื่อ" },
        // { key: "last_name", label: "สกุล" },
        { key: "position_name", label: "ตำแหน่ง" },
        // { key: "phone_number", label: "เบอร์โทรศัพท์" },
        // { key: "retire_year", label: "ปีเกษียณ" },
        // { key: "medal", label: "ระดับเข็ม" },
        { key: "create_plan", label: "บันทึกแผนการสอน" },
        { key: "user_status", label: "สถานะ" },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    formatPhoneNumber(text) {
      return text && text.length
        ? `${text.substring(0, 3)}-${text.substring(3, 6)}-${text.substring(
            6,
            10
          )}`
        : "-";
    },

    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.teacherTableCurrentPage = 1;
      this.teacherTableNumberOfPages = 1;
      this.teacherTableData = [];

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        numberOfPages = 1;

      const params = {},
        additionalParams = {};

      if (!ctx.downloadAll) {
        this.$set(params, "limit", ctx.perPage);
        this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
      }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "id", this.params.mStationId);
      }

      if (this.params && this.params.fiscalYearFrom) {
        this.$set(
          additionalParams,
          "fiscalYearFrom",
          this.params.fiscalYearFrom
        );
      }

      if (this.params && this.params.termFrom) {
        this.$set(additionalParams, "termFrom", this.params.termFrom);
      }

      if (this.params && this.params.fiscalYearTo) {
        this.$set(additionalParams, "fiscalYearTo", this.params.fiscalYearTo);
      }

      if (this.params && this.params.termTo) {
        this.$set(additionalParams, "termTo", this.params.termTo);
      }

      this.isFetching = true;

      try {
        const promise = await Report.api().getTeachingOverview(
          { ...params, ...additionalParams },
          { save: false }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        let teacherData = [];

        this.teacherTableData = [];
        this.teacherTableCurrentPage = 1;

        data = responseData.map((record) => {
          const { teachers = [] } = record;

          teacherData = [
            ...teacherData,
            ...teachers.map((teacher) => {
              // let medal = "ระดับต้น";

              // if (teacher.total_time) {
              //   if (teacher.total_time >= 200) {
              //     medal = "ระดับเหรียญทอง";
              //   } else if (teacher.total_time >= 150) {
              //     medal = "ระดับเหรียญเงิน";
              //   } else if (teacher.total_time >= 100) {
              //     medal = "ระดับเหรียญทองแดง";
              //   }
              // }

              let user_name = "";

              if (teacher) {
                const teacherRank = teacher.m_rank;

                user_name = `${teacher.first_name} ${teacher.last_name}`;

                if (teacherRank) {
                  user_name = `${teacherRank.m_rank_name} ${user_name}`;
                }
              }

              return {
                ...teacher,
                headquarter: record.m_headquarters_name,
                division: record.m_division_name,
                station: record.m_station_name,
                create_plan: teacher.create_plan ? "บันทึก" : "ไม่บันทึก",
                // rank: teacher.m_rank ? teacher.m_rank.m_rank_name : "-",
                // phone_number: teacher.phone_number
                //   ? `${teacher.phone_number.substring(
                //       0,
                //       3
                //     )}-${teacher.phone_number.substring(
                //       3,
                //       6
                //     )}-${teacher.phone_number.substring(6, 10)}`
                //   : "-",
                // medal,
                user_name,
                // retire_year: teacher.retire_year || "-",
              };
            }),
          ];

          return {
            ...record,
          };
        });

        this.teacherTableData = [...teacherData];

        this.teacherTableNumberOfPages = Math.ceil(
          this.teacherTableData.length / this.teacherTablePerPage
        );

        numberOfPages = number_of_pages;
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลรายงานสรุปทั้งหมดได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
        );
      } finally {
        this.isFetching = false;
      }

      if (!ctx.downloadAll) {
        this.numberOfPages = numberOfPages;
      }

      return data;
    },

    async teacherTableDataProvider(ctx) {
      const indexStart = (ctx.currentPage - 1) * ctx.perPage;
      const endIndex = indexStart + ctx.perPage;

      return this.teacherTableData.length
        ? this.teacherTableData.slice(indexStart, endIndex)
        : [];
    },

    onSeeDetail(item) {
      this.$emit("seeDetail", item);
    },

    editTeaching(item) {
      this.$emit("edit", item);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
