var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-content',[_c('page-title',{attrs:{"heading":"รายงานภาพรวมแผนการสอน"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.exportReport}},[(_vm.isExporting)?_c('b-spinner',{attrs:{"label":"exporting"}}):_c('span',[_vm._v("ดาวน์โหลดรายงาน")])],1)]},proxy:true}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('filter-fiscal-year-term',{model:{value:(_vm.formFiscalYearTerm),callback:function ($$v) {_vm.formFiscalYearTerm=$$v},expression:"formFiscalYearTerm"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('filter-master',{attrs:{"m-headquarter-id":!_vm.authUser.canAccessAllHeadQuarters ? _vm.authUser.mHeadquarterId : null,"m-division-id":!_vm.authUser.canAccessAllDivisions ? _vm.authUser.mDivisionId : null,"m-station-id":!_vm.authUser.canAccessAllStations ? _vm.authUser.mStationId : null,"disabledInput":{
          headQuarter:
            !_vm.authUser.canAccessAllHeadQuarters &&
            _vm.authUser.mHeadquarterId !== null,
          division:
            !_vm.authUser.canAccessAllDivisions && _vm.authUser.mDivisionId !== null,
          station:
            !_vm.authUser.canAccessAllStations && _vm.authUser.mStationId !== null,
        },"hiddenInput":{ school: true, schoolClass: true }},model:{value:(_vm.formfilter),callback:function ($$v) {_vm.formfilter=$$v},expression:"formfilter"}})],1)],1),_c('report-overview-table',{ref:"table",staticClass:"mt-4",attrs:{"fields":_vm.fields,"params":_vm.TableParams,"action-label":"","show-action":false,"see-detail":true,"show-delete":false},on:{"seeDetail":_vm.onSeeDetail,"delete":_vm.onDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }